import React from 'react';
import { client } from '../../api/apolloClient';
import { MainFilter } from '@izi-logistics/common/lib/components/BasicList/types';
import { FetchForwarderCompanies } from '@izi-logistics/common/lib/graphql';
import { BaseList } from '@izi-logistics/common/lib/components';
import { useTranslation } from 'react-i18next';
import { KEYS } from '@izi-logistics/common/lib/i18n';
import CompanyItem from './CompanyItem';
import { IZIBaseCompany } from '@izi-logistics/common/lib/stores';
import { TextOverflow } from '@izi-logistics/common/lib/components';
import { Grid, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import CompanyFormModal from '@izi-logistics/common/lib/components/Account/CompanyFormModal';
import { BaseCompany } from '@izi-logistics/common/lib/stores';
import { useLocalStore } from 'mobx-react-lite';
import { observer } from 'mobx-react';

export default observer(() => {
  const langOfferForm = useTranslation(KEYS.OFFER_FORM);
  const langForm = useTranslation(KEYS.FORM);
  const langMenu = useTranslation(KEYS.MENU);
  const langSettings = useTranslation(KEYS.SETTINGS);
  const theme = useTheme();
  const isMiddle = useMediaQuery(theme.breakpoints.down('sm'));

  const localStore: any = useLocalStore(() => ({
    key: 1,
    onChange() {
      localStore.key = Date.now();
    },
  }));
  const _config = {
    id: 'cu-ca-companies',
    title: (
      <Grid container alignItems={'center'} justify={'space-between'}>
        <TextOverflow variant={'h6'} style={{ fontWeight: 600 }}>
          {langMenu.t('menu_sidebar_companies_title')}
        </TextOverflow>
        <div>
          <CompanyFormModal record={BaseCompany.create({})} onChange={localStore.onChange} />
        </div>
      </Grid>
    ),
    noData: (
      <Grid container style={{ marginBottom: theme.spacing(5), flexDirection: 'column', alignItems: 'center' }}>
        <Typography variant={'subtitle1'} style={{ margin: theme.spacing(2, 0), textAlign: 'center' }}>
          {langForm.t('form_no_company_forwarder_text')}
        </Typography>
        <CompanyFormModal record={BaseCompany.create({})} onChange={localStore.onChange} />
      </Grid>
    ),
    headerStyle: { padding: theme.spacing(0, 1) },
    headerColumns: [
      { size: 2, name: langForm.t('form_input_name') },
      { size: 2, name: langForm.t('form_input_name_vat_code') },
      { size: 1, name: langForm.t('form_input_name_company_code') },
      // { size: 1, name: langForm.t('form_list_members') },
      { size: 3, name: langForm.t('form_input_name_address') },
      {
        size: 1,
        name: `${langSettings.t('settings_order_forwarder_offer_markup_percentage')}`,
      },
      { size: 3, name: langForm.t('form_input_name_joined_at') },
      isMiddle
        ? {
            size: 2,
            name: langOfferForm.t('offer_form_document_actions'),
            style: { display: 'flex', justifyContent: 'flex-end' },
          }
        : null,
    ].filter((e) => e),
    rowContent: (record: IZIBaseCompany) => (
      <CompanyItem config={{ client }} record={record} onChange={localStore.onChange} />
    ),
  };
  const defaultFilter = {} as MainFilter;

  const baseListProps = {
    client,
    query: FetchForwarderCompanies,
    defaultFilter,
    searchFields: ['name', 'vatCode'],
    sortFields: [
      { name: langForm.t('form_input_name'), value: 'name' },
      { name: langForm.t('form_input_name_vat_code'), value: 'vatCode' },
      { name: langForm.t('form_input_name_company_code'), value: 'companyCode' },
      { name: langForm.t('form_input_name_joined_at'), value: 'createdAt' },
    ],
  };

  return (
    <BaseList
      key={localStore.key}
      options={baseListProps}
      title={langMenu.t('menu_sidebar_companies_title')}
      config={_config}
    />
  );
});
